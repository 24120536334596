import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import icon1 from 'assets/icons/idea.png'
import icon2 from 'assets/icons/darts.png'
import icon3 from 'assets/icons/secure-shield.png'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  content: string
  isWhyGatigoPage: boolean
}

const Content = styled.article`
  h1,
  h2,
  h3 {
    position: relative;
    color: ${({ theme }) => theme.colors.primary};
    padding-top: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 8px;
      border-radius: 5px;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  /* p:has(+ h3) {
    margin-bottom: 30px;
  } */
`

const Features = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${Text} {
    margin-left: 10px;
  }

  ${({ theme }) => theme.media.lg.min} {
    ${Text} {
      margin-left: 20px;
    }
    :first-child {
      ${Text} {
        margin-left: 16px;
      }
    }
  }
`

const TextPage: React.FC<Props> = ({ content, isWhyGatigoPage }) => {
  const { lg } = useBreakpoint()

  return (
    <Container>
      {isWhyGatigoPage && (
        <Features>
          <Feature>
            <Icon src={icon1} size={lg ? 54 : 42} alt="idea" />
            <Text
              size={lg ? 20 : 18}
              weight={600}
              themecolor="black"
              margin="0"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: 'Innowacyjność' }}
            />
          </Feature>
          <Feature>
            <Icon src={icon2} size={lg ? 48 : 40} alt="darts" />
            <Text
              size={lg ? 20 : 18}
              weight={600}
              themecolor="black"
              margin="0"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: 'Funkcjonalność' }}
            />
          </Feature>
          <Feature>
            <Icon src={icon3} size={lg ? 46 : 38} alt="shield" />
            <Text
              size={lg ? 20 : 18}
              weight={600}
              themecolor="black"
              margin="0"
              transform="uppercase"
              dangerouslySetInnerHTML={{ __html: 'Niezawodność' }}
            />
          </Feature>
        </Features>
      )}
      <Content dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  )
}

export default TextPage
